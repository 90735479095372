import { initSentry } from '@/services/sentry';
import { settingsProviderInit } from '@/settings';
import { registerWidgets } from '@/widgets-lib/common/registerWidgets';
import { Toast } from '@/widgets-lib/common/toast';
import { inputWidgets } from '@/widgets-lib/generated/inputWidgets';
import { otherWidgets, outputWidgets } from '@/widgets-lib/generated/outputWidgets';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import Message from 'primevue/message';
import { createApp, h } from 'vue';
import vSelect from 'vue-select';
import Markdown from 'vue3-markdown-it';
import App from './App.vue';
import router from './router';
import { KeepAlive } from './services/keepalive';

(async () => {
  await settingsProviderInit();

  const pinia = createPinia();

  const app = createApp({
    render: () => h(App),
  });

  Toast.init();
  initSentry(app, router);
  KeepAlive.init();

  app.use(router);
  app.use(pinia);
  app.use(PrimeVue);

  app.mount('#app');
  app.component('VSelect', vSelect);
  app.component('Markdown', Markdown);
  app.component('Message', Message);

  registerWidgets(app, inputWidgets);
  registerWidgets(app, outputWidgets);
  registerWidgets(app, otherWidgets);
})();
